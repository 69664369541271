<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
  data(){
    return {
      
    }
  },
  created(){
    this.init()
  },
  mounted() {
    window.onresize=()=>{
     this.init()
    }
  },
  methods: {
    init(){
    let  Mobile= this.isMobile();
      if(!Mobile){
         location.href= window.otherUrl
      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
*{
  margin:0;
  padding:0;
}
body {
  margin: 0;
}
html,body,#app{
  width:100%;
  height:100%;
  overflow:hidden;
}
</style>
