import { createRouter, createWebHistory,createWebHashHistory } from "vue-router";

export const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
      isShow: true, // 是否在面包屑中显示
    },
    component: () => import("../views/home.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    meta: {
      title: "新闻",
      isShow: true, // 是否在面包屑中显示
    },
    component: () => import("../views/newsDetail.vue"),
  }
];
const router = createRouter({
  history: createWebHashHistory(), // 路由模式：history模式
  routes: routes
})

export default router;
